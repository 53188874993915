import React from "react"
import { graphql } from "gatsby"
import { Link } from "../elements"
import SEO from "../components/seo"
import { Header, Footer, Layout, Suggestion, BubbleTea } from "../components"
import { Paragraph, Line } from "../elements"
import styled from "styled-components"
import * as css from "../styles/article.module.css"
import { GatsbyImage } from "gatsby-plugin-image"
import { Divider, Avatar, Snackbar, IconButton } from "@mui/material"
import ruby from "../images/ruby.jpg"
import facebook from "../images/facebook.png"
import share from "../images/share.png"
import { GlobalStyle } from "../../src/elements/index"
import { tagMapper, copyToClipboard } from "../utils/tools"
import { FacebookProvider, ShareButton } from "react-facebook"
import CloseIcon from "@mui/icons-material/Close"
import favicon from "../images/favicon.ico"
import { MDXRenderer } from "gatsby-plugin-mdx"

const Wrapper = styled.div`
  padding: 0 20px;
`
const Dummy = styled.div`
  border-bottom: 1px solid #eee;
  margin-bottom: 30px;
  padding: 70px 0 30px;

  @media only screen and (max-width: 549px) {
    padding: 0px 0 0px;
  }
`

const Container = styled.div`
  text-align: center;
  margin: 0 54px 30px;
`

const Social = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0 30px;
  margin: 0 0 50px;
`
const FlexContainer = styled.div`
  display: flex;
`

const Icon = styled(Avatar)`
  margin: 5px;

  :hover {
    cursor: pointer;
  }
`

const FacebookButton = styled(ShareButton)`
  padding: 0;
  border: 0;
  background: none;
  margin: 0 5px 0 10px;
  :hover {
    cursor: pointer;
  }
`
const By = styled.span`
  line-height: 50px;
  margin: 0 10px;
  font-family: Montserrat, Noto Sans TC, sans-serif;
  font-style: italic;
`
const Author = styled.span`
  line-height: 50px;
  font-family: "Overpass", sans-serif;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #000;
`

const Post = ({ data: { mdx, allMdx }, location }) => {
  // console.log("mdx", mdx)
  const [open, setOpen] = React.useState(false)
  const date = new Date(mdx.frontmatter.date)
  const img = mdx.frontmatter.featureImage
  const year = date.getFullYear()
  const day = date.getDate()
  let month = date.getMonth() + 1
  if (month < 10) {
    month = "0" + month
  }
  const type = mdx.frontmatter.type
  const imagePath =
    type === "about"
      ? favicon
      : img.childImageSharp.gatsbyImageData.images.sources[0].srcSet
  const previewImage = "https://ndailynotes.com" + imagePath
  const path =
    (type === "about"
      ? mdx.frontmatter.slug
      : "/articles/" + mdx.frontmatter.slug) + "/"

  const handleClick = () => {
    copyToClipboard(location.href)
    setOpen(true)
  }

  const handleClose = reason => {
    if (reason === "clickaway") {
      return
    }
    setOpen(false)
  }
  return (
    <>
      <SEO
        title={mdx.frontmatter.title}
        description={mdx.frontmatter.excerpt}
        url={"https://ndailynotes.com" + path}
        previewImage={previewImage}
        type="article"
      />
      <GlobalStyle />
      <Header />
      <Dummy />
      <Wrapper>
        <Layout
          left={
            <>
              <Container>
                <span className={css.catagory}>
                  {mdx.frontmatter.type === "about" ? (
                    <></>
                  ) : (
                    <Link
                      to={"/category/" + mdx.frontmatter.tag}
                      className="tagHover"
                    >
                      {tagMapper(mdx.frontmatter.tag)}
                    </Link>
                  )}
                </span>
                <h2 className={css.article_title}>
                  <Link to="/">{mdx.frontmatter.title}</Link>
                </h2>
                <span>
                  <span className={css.date}>
                    <Link to={"/" + year + "/" + month}>
                      {mdx.frontmatter.type === "about"
                        ? ""
                        : year + " 年 " + month + " 月 " + day + " 日 "}
                    </Link>
                  </span>
                </span>
              </Container>
              {img ? (
                <GatsbyImage
                  image={img.childImageSharp.gatsbyImageData}
                  alt={mdx.frontmatter.slug}
                />
              ) : (
                ""
              )}
              <Paragraph>
                <MDXRenderer>{mdx.body}</MDXRenderer>
              </Paragraph>
              {<BubbleTea />}
              <Social>
                <FlexContainer>
                  <Avatar alt="Ruby" src={ruby} />
                  <By>by</By>
                  <Author>N</Author>
                </FlexContainer>
                <FlexContainer style={{ alignItems: "center" }}>
                  <FacebookProvider appId="751338822287965">
                    <FacebookButton
                      href={"https://ndailynotes.com/" + mdx.frontmatter.slug}
                    >
                      <Avatar variant="rounded" src={facebook} />
                    </FacebookButton>
                  </FacebookProvider>
                  <Line
                    url={"https://ndailynotes.com/" + mdx.frontmatter.slug}
                  />
                  <div>
                    <Icon
                      variant="rounded"
                      src={share}
                      style={{ backgroundColor: "#ee8e2d" }}
                      onClick={handleClick}
                    />
                    <Snackbar
                      open={open}
                      autoHideDuration={1500}
                      onClose={handleClose}
                      message="網址已成功複製！"
                      action={
                        <React.Fragment>
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            onClick={handleClose}
                          >
                            <CloseIcon />
                          </IconButton>
                        </React.Fragment>
                      }
                    ></Snackbar>
                  </div>
                </FlexContainer>
              </Social>
              {/* <HyvorTalk.Embed websiteId={1177} loadMode="scroll" /> */}
              <Divider />
              {mdx.frontmatter.type !== "about" && (
                <Suggestion data={allMdx.edges} />
              )}
            </>
          }
        />
      </Wrapper>
      <Footer />
    </>
  )
}

export const query = graphql`
  query MyPost($id: String!, $tag: String) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        date
        type
        title
        tag
        slug
        excerpt
        featureImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    allMdx(
      filter: { frontmatter: { tag: { eq: $tag } }, id: { ne: $id } }
      limit: 3
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            slug
            featureImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`

export default Post
